<template>
  <div class="conteudos_tematicos_bg">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="mt-md-5">
          <p
            style="background-color: #EDBD43; padding: 0.5em; border-radius: 4px; font-size: 1em;"
          >
            <strong> Relações Comunitárias </strong>
          </p>
        </div>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p class="pa-3 mb-2 my-auto" style="background-color: #F3F3F3;">
                  “A convivência era diferente porque ficava tudo perto, escola,
                  igreja, campo de futebol. Para mim, como mãe, lá era muito
                  melhor. Aqui, quando eles saem, só consigo comunicar por
                  telefone, são seis filhos. A preocupação é maior, a gente não
                  sabe o que está acontecendo (Maria do Carmo Sena, atingida,
                  2019).”
                </p>
                <p>
                  Compulsoriamente expulsos de suas casas e comunidades pelo
                  rompimento da barragem, a maioria das pessoas atingidas veio a
                  se refugiar no centro urbano de Mariana, muitas vezes levando
                  na bagagem apenas memórias dos lugares de origem. Perdendo as
                  atividades produtivas que até então desenvolviam e postos de
                  trabalho formal ou informal, as famílias passaram a precisar
                  do auxílio financeiro das empresas. As mulheres, em especial,
                  foram constrangidas a conviver com o desemprego, perda de
                  autonomia financeira e falta de apoio social, na medida em que
                  precisam dedicar-se ainda mais ao cuidado de toda a família.
                </p>
                <p>
                  Além da perda de renda das famílias, no êxodo forçado também
                  se desarticulam as relações comunitárias, o que se desdobra em
                  danos à sociabilidade, à memória das comunidades, à
                  continuidade dos festejos. Contudo, neste texto analisaremos
                  uma consequência específica que implica sobretudo na vida das
                  mulheres: a perda da rede de apoio nos cuidados das crianças,
                  pessoas com deficiência e idosos. Fabrícia Maria Machado
                  Tavares, atingida de Paracatu de Cima, conta que:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Sei que sou privilegiada de ainda ter minha casa e saber que meu
            filho tem espaço para brincar, enquanto muitos atingidos que
            perderam suas casas tiveram seus modos de vida totalmente alterados.
            Muitos vivem em um meio urbano, por vezes sem ter espaço suficiente
            para a criança brincar e, diante de todos os obstáculos e desafios
            impostos pela pandemia, imagino que seja mais difícil ainda para
            várias mães atingidas e seus filhos (BENTO; TAVARES; SILVA, 2021, p.
            12).”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O apoio mútuo entre as mulheres, que são a força motriz da
                  economia do cuidado, é característico das comunidades
                  tradicionais, nas quais os laços sociais e de parentesco se
                  irradiam para diferentes dimensões da vida cotidiana. Durante
                  o processo de cadastramento, por exemplo, as relações
                  familiares e de compadrio entrelaçam-se às relações de
                  vizinhança e eram reiteradas nas intersecções entre as
                  famílias. Seja por casamento, seja por consanguinidade, todos
                  são parentes em algum ponto, por mais que distantes,
                  constituíam, portanto, vínculos relacionais.
                </p>
                <p>
                  Havia, assim, uma corresponsabilidade no cuidado das crianças
                  e adolescentes respaldada na confiança de que algum adulto,
                  que ocasionalmente estivesse por perto ou fosse acionado,
                  agiria no acolhimento ou suporte num tombo ou repreensão de
                  peraltices, se necessário. Dessa forma, as mulheres dispunham
                  de tempo para o exercício das tarefas domésticas, cultivo das
                  hortas e trato das criações, bem como para dedicar-se a outras
                  atividades, como cuidar de si ou realizar uma visita a uma
                  amiga. Era comum, então, a circulação de crianças entre as
                  casas e o livre brincar nas ruas, quadras, rios e quintais.
                </p>
                <p>
                  Ademais, a proximidade das relações desdobrava-se em trocas de
                  favores, possibilitando às mães o deslocamento para trabalho,
                  estudo e consultas em Mariana sem a preocupação com os
                  cuidados dos filhos durante as horas nas quais se ausentavam.
                  São comuns também os casos de crianças que temporariamente
                  moravam com avós ou tios, enquanto os pais trabalhavam na
                  cidade ou nas comunidades do entorno.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_05.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Daniela Felix e Wan Campos. Jornal A Sirene, 2018.
          </p>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Gênero /",
          href: "conteudos_tematicos_genero",
        },
        {
          text: "Relações Comunitárias",
          href: "conteudos_tematicos_rel_comunitarias",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
